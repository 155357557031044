import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HeroSlider from 'components/HeroSlider';
import StylistTools from 'blocks/StylistTools';
import Videos from 'blocks/Videos';
import url from 'helpers/url';
import { useIntl, Link } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    ImageTextGrid,
} from '../kenra-storybook/index';

let Color = require('color');

const { StSectionInfo, StTitle, StLinkMore } = SharedStyles;

export default function ColorLanding({ page }) {
    let intl = useIntl();
    return (
        <>
            <Spacing>
                <HeroSlider slides={page.slides} />
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>
                        {documentToReactComponents(JSON.parse(page.text.raw || '{}'))}
                        <StLinkMore styleBig>
                            <Link to={url.colorList}>
                                {intl.formatMessage({ id: 'product-view-all' })}
                            </Link>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            {page.bottomImages && (
                <Spacing removeSpaceTop>
                    <ImageTextGrid
                        styleSquare
                        rowRevers
                        items={page.bottomImages.map((image, index) => {
                            let bgColor = get(
                                page,
                                `bottomColors[${index}]`,
                                '#d3d3d3'
                            );
                            return {
                                rowBg: bgColor,
                                img: get(image, 'localFile.publicURL'),
                                title: get(page, `bottomTitles[${index}]`),
                                text: get(page, `bottomTexts[${index}]`),
                                linkText: get(
                                    page,
                                    `bottomButtonTexts[${index}]`
                                ),
                                linkHref: get(
                                    page,
                                    `bottomButtonLinks[${index}]`
                                ),
                                linkStyleWhite: Color(bgColor).isDark(),
                                rowColorWhite: Color(bgColor).isDark(),
                            };
                        })}
                    />
                </Spacing>
            )}

            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.videos}
                    bgImage={get(page, 'videosImage.localFile.publicURL')}
                />
            </Spacing>

            {page.stylistTools && (
                <Spacing>
                    <StylistTools tools={page.stylistTools} />
                </Spacing>
            )}
        </>
    );
}
