import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';
import CustomLink from 'components/Link';

import {
    Slider,
    SliderTools,
    SharedStyles,
    Container,
} from '../kenra-storybook/index';

const { StSliderTools } = SliderTools;
const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

const StylistTools = ({ intl, tools, count }) => {
    return (
        <StSectionMobileViewMore>
            <StSectionTitle>
                <Container>
                    <h2 style={{ color: '#000', fontSize: '28px' }}>
                        {intl.formatMessage({ id: 'stylist-tools-title' })}
                    </h2>
                    <StLink mobileStyleLinkMore>
                        <Link to={urls.tools}>{`${intl.formatMessage({
                            id: 'view-all-text',
                        })} (${count ? count : tools.length})`}</Link>
                    </StLink>
                </Container>
            </StSectionTitle>
            <Slider settings={{ slidesPerView: 'auto' }}>
                {tools.map((manual, index) => (
                    <StSliderTools key={index}>
                        <img
                            src={get(
                                manual,
                                'image.localFile.square.resize.src'
                            )}
                            alt=""
                        />
                        <h3>{manual.title}</h3>
                        <StLink>
                            <CustomLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={get(manual, 'file.localFile.publicURL')}
                            >
                                {intl.formatMessage({ id: 'download-text' })}
                            </CustomLink>
                        </StLink>
                    </StSliderTools>
                ))}
            </Slider>
        </StSectionMobileViewMore>
    );
};

export default injectIntl(StylistTools);
